import fetch from 'isomorphic-unfetch';
import cookie from 'cookie';
import {
  ApolloLink,
  ApolloClient,
  HttpLink,
  InMemoryCache,
} from '@apollo/client';
import { setContext } from '@apollo/client/link/context';

const createHttpLink = (headers) => {
  const httpLink = new HttpLink({
    uri: process.env.NEXT_PUBLIC_OLD_API_URI,
    headers,
    fetch,
  });
  return httpLink;
};

export default function createApolloClient(initialState, headers, req: any) {
  const ssrMode = typeof window === 'undefined';
  const httpLink = createHttpLink(headers);
  const authLink = setContext((_request, { headers }) => {
    const token = getAuthCookie(req);
    console.log('token apollo', token);
    return {
      headers: {
        ...headers,
        authorization: token ?? '',
      },
    };
  });
  const link = ApolloLink.from([authLink, httpLink]);

  return new ApolloClient({
    ssrMode,
    link,
    cache: new InMemoryCache().restore(initialState),
  });
}

function getAuthCookie(req?: any) {
  const cookies = cookie.parse(
    req
      ? req.headers.cookie || ''
      : typeof document !== 'undefined'
      ? document.cookie
      : ''
  );

  return cookies.auth;
}
