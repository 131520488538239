import Image from "next/image";
import { Layout } from "../components/layout";
import { withApollo } from "../lib/with-apollo";
import Logo from "../public/img/white-logo-black-bg.png";

const Index: React.FC = () => {
  return (
    <Layout>
      <h1 className="text-center">Tierversand</h1>

      <div>
        <p>
          <strong>
            Tierversand vom Fachmann für <em>Privatpersonen und Gewerbetreibende</em>.
          </strong>
        </p>
        <p>
          <strong>Rabatte für Mitglieder in unserer Facebookgruppe „Kleintiertransporte“</strong>
        </p>
        <p>
          Mitglieder in unserer Facebookgruppe „Kleintiertransporte“ erhalten 1,-€ Rabatt* auf jeden Tiertransport der
          bei Gardow Logistik beauftragt wird. Somit schon{" "}
          <strong>
            ab 33,50€ inkl. gesetzl. MwSt möglich.
            <br />
          </strong>
        </p>
        <p>Tierversand für Mitglieder in der Facebook Gruppe gibt es dann schon ab 33,50€ inkl. gesetzl. MwSt.</p>
        <p>
          <b>Liebe Kleintierfreunde ,</b>
        </p>
        <p>
          <b>
            ob Sie Käufer oder Verkäufer, Privat oder Gewerbetreibender sind, mit unserem Tierversand werden Ihre Tiere
            schonend, behutsam und so
          </b>
          <b> stressfrei wie möglich</b>
          <b> über Nacht zu Ihnen nach Hause</b>
          <b>, zu Käufern oder in Tierkliniken</b>
          <b> geliefert. Tierversand</b>
          <b>
            {" "}
            ist möglich innerhalb Deutschland, innerhalb Österreichs sowie von und nach Österreich. In und aus der
            Schweiz können wir ab der nähe der Grenze übernehmen (Selbstanlieferung und Abholung in einer Grenznahen
            Station nähe österreichischer/deutscher Grenze)
          </b>
          <b>.</b>
        </p>
        <p>
          <b>
            Gardow Logistik<i> </i>ist Ihr Spezialist für den Tiertransport von Kleintieren mit jahrelanger Erfahrung.
          </b>
        </p>
        <h3>
          <span className="text-theme-lightGreen">
            <b>Wir transportieren für Sie im Tiertransportbereich: </b>
          </span>
        </h3>
        <ul>
          <li>
            <strong>Geflügel, Tauben </strong>
            <br />
            Tauben (alle Arten), Hühner (alle Arten), Enten (alle Arten), Fasan, Gänse, Gänseküken, Küken, Perlhühner,
            Rebhuhn, Truthahn, Wachteln
          </li>
        </ul>
        <h6></h6>
        <ul>
          <li>
            <strong>Ziervögel </strong>
            <br />
            Sittiche, Kanarien, Finken, Sperlinge. Pfauen (nur Jungtiere ohne lange Schwanzfedern). Papageien und
            papageienartige Vögel.
          </li>
        </ul>
        <h6></h6>
        <ul>
          <li>
            <strong>Kleinsäuger </strong>
            <br />
            Mäuse, Ratten, Hamster, Meerschweinchen, Chinchillas, Degus, Hörnchen, Igel. Kaninchen und Hasen.
          </li>
        </ul>
        <h6></h6>
        <ul>
          <li>
            <strong>Reptilien </strong>
            <br />
            <strong>
              <em>ungiftige</em>
            </strong>{" "}
            Schlangen, Schildkröten, Geckos, Frösche, Agamen, Chamäleons, Echsen, Salamander
          </li>
        </ul>
        <h6></h6>
        <ul>
          <li>
            <strong>Fische und Amphibien </strong>
            <br />
            Fische, Korallen, Wasserpflanzen, Axolotl usw.
          </li>
        </ul>
        <h6></h6>
        <ul>
          <li>
            <strong>Insekten </strong>
            <br />
            <strong>
              <em>ungiftige</em>
            </strong>{" "}
            Spinnen, Bienen, Ameisen usw.
          </li>
        </ul>
        <p></p>
        <h3>
          <b>
            <span className="text-theme-red">Vom Transport ausgeschlossen sind folgende Tiere:</span>
          </b>
        </h3>
        <p>
          <b>
            Tiere welche für einen eventuellen weiteren Transporttag wegen Transporthindernissen jeglicher Art oder
            einen eventuellen Rücktransport nicht geeignet sind. Ausgeschlossen sind: Alle giftige Tierarten,&nbsp;
            Hunde, Katzen, Frettchen, kranke und erkrankte Tiere aller Art, Affen (Primaten) jeglicher Art, Alligatoren,
            Krokodile, Kaimane, Skorpione, Reptilien und Amphibien (wenn deren Länge 50 cm überschreitet), Eier (wenn
            sie zum Verzehr bestimmt oder angebrütet sind), Füchse, Hundearten jeglicher Art, Huftiere jeglicher Art,
            Reiher, Störche, Kraniche (wegen der Verletzungsgefahr durch die langen Beine), Schlangen, Reptilien (wenn
            sie giftig sind oder deren Durchmesser 5 cm überschreitet), Stinktiere (auch wenn diese entdrüst sind, da in
            der BRD verboten!), Giftige Spinnen, Waschbären jeglicher Art sowie exotische und geschützte Tierarten die
            einem Versand- oder Veräußerungsverbot unterliegen.
            <br />
          </b>
          <b>
            <br />
            Übernacht werden Ihre Tiere sicher, behutsam und so stressfrei wie möglich transportiert und schon am
            nächsten Tag in Deutschland bundesweit in der Regel
            <br />
            bis 12 Uhr zugestellt. Außerhalb Deutschlands bis 17 Uhr.
          </b>
        </p>
        <p>
          <strong>Tierversandtage sind Montag / Dienstag /Mittwoch. Am Donnerstag nur Reptilien und Fische !</strong>
        </p>
        <p>
          <strong>LIEBE TIERFREUNDE wir bieten Ihnen folgenden Service:</strong>
        </p>
        <ul>
          <li>
            <b>
              <b>Online Preisrechner</b>
            </b>
          </li>
          <li>
            <strong>Online Postleitzahlen Überprüfung</strong>
          </li>
          <li>
            <b>
              <b>Online Tiertransport Auftrag – 24 Stunden / 7 Tage ausfüllbar</b>
            </b>
          </li>
          <li>
            <b>mehrere Zahlarten möglich</b>
            <b>
              <br />
            </b>
          </li>
          <li>
            <b>Kostenloses Versandprogramm</b>
          </li>
          <li>
            <b>Sendungsverfolgung</b>
          </li>
          <li>
            <b>Tiertransport Aufkleber fertig ausgefüllt in Bestätigungsmail und blanko zum Downloaden</b>
          </li>
          <li>
            <b>Keine versteckte Kosten</b>
          </li>
        </ul>
        <p>
          <b> </b>
          <br />
          <b>
            Grundsätzlich können die Tiere im Laufe des Tages bei Ihnen abgeholt werden. Sie können ein Zeitfenster in
            unserem Online-Tiertransportauftrag auswählen.{" "}
          </b>
          <b>Zum Beispiel: 12:00 Uhr bis 15:00 Uhr </b>
          <b>oder </b>
          <b>14:00 Uhr bis 17:00 Uhr.</b>
          <br />
          <b>Außerhalb Deutschlands ist die Abhol- und Zustellzeit von 8.00 Uhr bis 17.00 Uhr. </b>
        </p>
        <p>
          <b>
            Selten bietet ein Tierversand all diese Serviceleistungen an.
            <br />
            Vergleichen Sie selbst!
          </b>
        </p>
        <p>
          <strong>D</strong>
          <b>
            ie Sicherheit der Kleintiere steht ierbei ganz klar im Vordergrund.
            <br />
            Unser Ziel ist es Ihnen ein Service anzubieten, der speziell für Ihre Tiertransporte ausgearbeitet wurde.
          </b>
        </p>
        <p>
          <strong>
            Geben Sie Ihre Lieblinge in die Hände von Spezialisten im Bereich Kleintierversand mit jahrelanger
            Erfahrung.
          </strong>
        </p>
        <p>
          <b>
            Für den Transport gilt eine abweichende Berechnung, wenn die Verpackung der Tiere ein Volumengewicht
            darstellt. Das Volumengewicht errechnet sich aus folgender Formel: Sie nehmen bitte die Verpackung der Tiere
            und messen die Länge in cm x Breite in cm x Höhe in cm : durch 6000 = Volumen KG (Achtung immer das äußerste
            Maß nehmen inkl. aller Abstandshalter oder Griffe). Ist dieses Gewicht höher als das tatsächliche Gewicht
            der Tiersendung, wird die Sendung zum Preis des Volumengewichtes abgerechnet.
          </b>
        </p>
        <p>
          <b>
            Wir freuen uns auf Ihren Auftrag und das Vertrauen was Sie uns damit entgegenbringen.
            <br />
          </b>
          <br />
          <b>
            <i>
              ACHTUNG LIEBE TIERFREUNDE!
              <br />
              AUFGRUND DEN WITTERUNGSVERHÄLTNISSEN, BITTE UNBEDINGT AUF DIE ARTGERECHTE VERPACKUNG ACHTEN !
            </i>
          </b>
        </p>
        <p>
          <strong>
            Die Transportfahrzeuge sind NICHT beheizt / gekühlt. Bei temperaturempfindlichen Tieren eventuell den
            Transport verschieben. Eine Kenntnis über die Bedürfnisse der Tiere und/oder die Kenntnis mit dem Umgang von
            Heatpacks setzen wir dem Auftraggeber / Versender voraus.
          </strong>
        </p>
        <p>
          <strong>
            Bitte lesen Sie unsere AGB für Tiertransporte durch, denn mit Beauftragung eines Tiertransportes erkennen
            Sie automatisch unsere AGB für Tiertransporte an.
            <i>
              <br />
            </i>
          </strong>
        </p>
        <p>Wir sind ein zugelassenes Tiertransportunternehmen</p>
        <p>
          Zulassung gemäß Artikel 10 Abs. 1 der Verordnung (EG) Nr. 1/2005 des Rates vom 22.12.2004 über den Schutz von
          Tieren beim Transport.
        </p>
        <p>Ihr Gardow Logistik / Tiertransport-Team</p>
        <p>
          Die Dienstleistungsmarke „kleintierkurier.de“ wird vertreten durch:
          <br />
          gardow-logistics, Benzstraße 15, 71701 Schwieberdingen
        </p>
        <p>
          <Image src={Logo} width={300} height={180} />
        </p>
      </div>
    </Layout>
  );
};

export default withApollo()(Index);
